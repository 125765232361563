import React from "react";
import { useField, useFormikContext } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

export function PercentualHtml({
    ...props
}) {

    return (
        <>
            <NumberFormat thousandSeparator={true} suffix={'%'}
                displayType={'text'}
                decimalScale={2}
                decimalSeparator=","
                fixedDecimalScale
                thousandSeparator="."
                {...props}
            />

        </>
    );
}
